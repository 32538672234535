import { GOT_USER_ANALYTICS, GOT_SPONSOR_ANALYTICS, GOT_PRODUCT_ANALYTICS } from "../../constants/ActionTypes";

const initialAnalytics = {
  user_analytics: { analytics_list: [] },
  sponsor_analytics: { analytics_list: [] },
  product_analytics: { analytics_list: [] }
};

export default (state = initialAnalytics, action) => {
  switch (action.type) {
    case GOT_USER_ANALYTICS: {
      return {
        ...state,
        user_analytics: action.payload
      };
    }
    case GOT_SPONSOR_ANALYTICS: {
      return {
        ...state,
        sponsor_analytics: action.payload
      };
    }
    case GOT_PRODUCT_ANALYTICS: {
      return {
        ...state,
        product_analytics: action.payload
      };
    }
    default:
      return state;
  }
};
