import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Card, message } from "antd";
import { Spin } from "utils";

import UserUpdateForm from "./UserUpdateForm";
import { userData, userLocation, updateUser, updateUserLocation } from "redux/actions/User";

class UpdateUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: null,
      user: {},
      userLocation: null,
      notFound: false,
      loading: true
    };
  }

  async componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let user = await this.props.userData(this.props.match.params.id);
    let location = await this.props.userLocation(this.props.match.params.id);
    if (user && user.isPermanent === true) {
      let userLocation = this.state.userLocation;
      if(location) {
        userLocation = location;
      }
      this.setState({
        uid: this.props.match.params.id,
        user,
        userLocation,
        loading: false
      });
    } else {
      message.error("User not found or you can't update this user.");
      this.setState({ notFound: true });
    }
  }

  updateUser = async (uid, user, profile_photo, location) => {
    this.setState({ loading: true });
    let response = await this.props.updateUser(uid, user, profile_photo);
    let locationResponse = await this.props.updateUserLocation(uid, location);
    this.setState({
      uid: response.uid,
      user: response.user,
      userLocation: locationResponse.location,
      loading: false
    });
    message.success("User updated successfully.");
  };

  render() {
    if (!this.state.notFound) {
      if (this.state.loading) {
        return (
          <Spin tip="Please wait ..." spinning={this.state.loading}></Spin>
        );
      } else {
        return (
          <Card className="gx-card" title="Update User Profile">
            <UserUpdateForm
              updateUser={this.updateUser}
              updateUserLocation={this.props.updateUserLocation}
              uid={this.state.uid}
              user={this.state.user}
              userLocation={this.state.userLocation}
            />
          </Card>
        );
      }
    } else {
      return <Redirect to={"/user/permanent"} />;
    }
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  { userData, userLocation, updateUser, updateUserLocation }
)(UpdateUser);
