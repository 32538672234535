import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Col, Row, message } from "antd";

import Contact from "components/profile/Contact/index";
import { Auxiliary, Spin } from "utils";
import ProfileHeader from "components/profile/ProfileHeader/index";

import { userData } from "redux/actions/User";

class ViewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: "",
      user: {},
      notFound: false,
      loading: true
    };
  }

  async componentDidMount() {
    let user = await this.props.userData(this.props.match.params.id);
    if (user) {
      this.setState({
        uid: this.props.match.params.id,
        user: user,
        loading: false
      });
    } else {
      message.error("User not found.");
      this.setState({ notFound: true });
    }
  }

  render() {
    if (!this.state.notFound) {
      if (this.state.loading) {
        return (
          <Spin tip="Please wait ..." spinning={this.state.loading}></Spin>
        );
      } else {
        return (
          <Auxiliary>
            <ProfileHeader user={this.state.user} />
            <div className="gx-profile-content">
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Contact user={this.state.user} />
                </Col>
              </Row>
            </div>
          </Auxiliary>
        );
      }
    } else {
      return <Redirect to={"/user/manage"} />;
    }
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  {
    userData
  }
)(ViewUser);
