import React, { Component } from "react";
import _ from "lodash";
import { GoogleMap, Marker, withGoogleMap, Circle } from "react-google-maps";
import SearchBox from "react-google-maps/lib/components/places/SearchBox";

const refs = {};
const google = window.google;

class LocationPicker extends Component {
  onDragEnd = e => {
    this.props.onLocationChange({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  onMapMounted = ref => {
    refs.map = ref;
  };

  onSearchBoxMounted = ref => {
    refs.searchBox = ref;
  };

  onPlacesChanged = () => {
    const places = refs.searchBox.getPlaces();
    const bounds = new google.maps.LatLngBounds();

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    const nextMarkers = places.map(place => ({
      position: place.geometry.location
    }));
    const nextCenter = _.get(nextMarkers, "0.position", this.props.location);

    this.props.onLocationChange({
      lat: nextCenter.lat(),
      lng: nextCenter.lng()
    });
  };

  render() {
    let { location, circleRadius, maxOutRadius } = this.props;
    let displayCircle = true;
    if (circleRadius === maxOutRadius) {
      displayCircle = false;
    }
    circleRadius = circleRadius * 1000;

    return (
      <GoogleMap
        defaultZoom={5}
        center={location}
        ref={this.onMapMounted}
        defaultOptions={{
          streetViewControl: false,
          mapTypeControl: true,
          fullscreenControl: false,
          mapTypeControlOptions: {
            mapTypeIds: ["roadmap", "satellite"]
          }
        }}
      >
        <SearchBox
          ref={this.onSearchBoxMounted}
          controlPosition={google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={this.onPlacesChanged}
        >
          <input
            type="text"
            placeholder="Search a place"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `40px`,
              marginTop: `10px`,
              // marginLeft: `10px`,
              padding: `0 10px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `16px`,
              outline: `none`,
              textOverflow: `ellipses`
            }}
          />
        </SearchBox>
        <Marker
          position={location}
          draggable={true}
          onDragEnd={this.onDragEnd}
        />
        {displayCircle && (
          <Circle
            center={location}
            radius={circleRadius}
            options={{ strokeColor: "#ff0000" }}
          />
        )}
      </GoogleMap>
    );
  }
}

export default withGoogleMap(props => <LocationPicker {...props} />);
