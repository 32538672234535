import React from "react";
import { Route, Switch } from "react-router-dom";

import UserAnalytics from "./UserAnalytics";
import SponsorAnalytics from "./SponsorAnalytics";
import ProductAnalytics from "./ProductAnalytics";
import NotFound from "containers/NotFound";

const AnalyticsRoutes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/user`} component={UserAnalytics} />
    <Route path={`${match.url}/sponsor`} component={SponsorAnalytics} />
    <Route path={`${match.url}/product`} component={ProductAnalytics} />
    <Route component={NotFound} />
  </Switch>
);

export default AnalyticsRoutes;
