import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Card, Table, Button } from "antd";

import { loadAnalytics } from "redux/actions/Analytics";
import { SPONSOR_ANALYTICS } from "../../constants/AppConstants";

const ts = new Date().getTime();
const tsYesterday = ts - 24 * 3600 * 1000;
const columns = [
  {
    title: "User",
    dataIndex: "item.profileData.name",
    width: "40%"
  },
  {
    title: "Last 24 Hours",
    children: [
      {
        title: "Searched",
        dataIndex: "searched",
        width: "15%",
        render: (searched) => {
          let filtered = _.filter(searched, val => {
            return val > tsYesterday;
          });
          return Object.keys(filtered).length;
        }
      },
      {
        title: "Viewed",
        dataIndex: "viewed",
        width: "15%",
        render: (viewed) => {
          let filtered = _.filter(viewed, val => {
            return val > tsYesterday;
          });
          return Object.keys(filtered).length;
        }
      }
    ]
  },
  {
    title: "All time",
    children: [
      {
        title: "Searched",
        dataIndex: "searched",
        key: "allTimeSearched",
        width: "15%",
        render: (searched, item) => {
          if(item && item.searched) {
            return Object.keys(item.searched).length;
          } else {
              return '0';
          }
        }
      },
      {
        title: "Viewed",
        dataIndex: "viewed",
        key: "allTimeViewed",
        width: "15%",
        render: (viewed, item) => {
          if(item && item.viewed) {
            return Object.keys(item.viewed).length;
          } else {
            return '0';
          }
        }
      }
    ]
  }
];

class SponsorAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analytics_list: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        lastKey: "",
        firstKey: "",
        lastPage: 0
      },
      loading: false
    };
  }

  refresh = () => {
    this.setState({
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        lastKey: "",
        firstKey: "",
        lastPage: 0
      }
    });
    this.onCollectionUpdate();
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.onCollectionUpdate({
      pagination,
      filters,
      sorter
    });
  };

  onCollectionUpdate(params = {}) {
    this.setState({ loading: true });
    const pagination = { ...this.state.pagination };
    if (params.pagination) {
      pagination.current = params.pagination.current;
    }

    this.props.loadAnalytics(pagination, SPONSOR_ANALYTICS);
  }

  componentDidMount() {
    this.onCollectionUpdate();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.sponsor_analytics &&
      nextProps.sponsor_analytics.analytics_list &&
      nextProps.sponsor_analytics.pagination
    ) {
      const pagination = nextProps.sponsor_analytics.pagination;
      const analytics_list = nextProps.sponsor_analytics.analytics_list;

      this.setState({
        loading: false,
        analytics_list,
        pagination
      });
    }
  }

  render() {
    return (
      <Card
        className="gx-card"
        title="Sponsor Stats"
        extra={
          <Button
            className="gx-btn-block ant-btn"
            type="primary"
            aria-label="add"
            onClick={this.refresh}
          >
            <span>Refresh</span>
          </Button>
        }
      >
        <Table
          columns={columns}
          bordered
          size={"small"}
          dataSource={this.state.analytics_list}
          rowKey={record => record.uid}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.handleTableChange}
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, analytics }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { sponsor_analytics } = analytics;
  return { loader, alertMessage, showMessage, authUser, sponsor_analytics };
};

export default connect(mapStateToProps, { loadAnalytics })(SponsorAnalytics);
