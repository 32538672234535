import React, { Component } from "react";
import { Button, Form, Input } from "antd";
import _ from "lodash";

const FormItem = Form.Item;

class UserCreateForm extends Component {
  state = {
    user: {
      isPermanent: true,
      active: true,
      activeProfileType: "user",
      update: true,
      productData: {
        name: "",
        avatar: "",
        status: "Available",
        socialProfiles: {
          email: "",
          facebook: "",
          instagram: "",
          linkedin: "",
          snapchat: "",
          twitter: "",
          whatsapp: "",
          pinterest: "",
          tiktok: "",
          youtube: "",
          menu: "",
          wifi: {
            verified: true
          },
          wifi_ssid: {
            active: true,
            verified: true
          }
        }
      },
      userData: {
        name: "",
        avatar: "",
        status: "Available",
        socialProfiles: {
          email: "",
          facebook: "",
          instagram: "",
          linkedin: "",
          snapchat: "",
          twitter: "",
          whatsapp: "",
          pinterest: "",
          tiktok: "",
          youtube: "",
          menu: "",
          wifi: {
            verified: true
          },
          wifi_ssid: {
            active: true,
            verified: true
          }
        }
      }
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        var user = _.merge(this.state.user, values.user);
        this.setState({ user });
        this.props.createUser(this.state.user);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="Name">
          {getFieldDecorator("user.userData.name", {
            initialValue: user.userData.name,
            rules: [
              {
                required: true,
                message: "Please input user's name!",
                whitespace: true
              }
            ]
          })(<Input />)}
        </FormItem>

        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Create & Proceed
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(UserCreateForm);
