import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Table, Tag, Button, Popconfirm } from "antd";

import { sponsorsList, deleteSponsor } from "redux/actions/Sponsor";

class ManageSponsor extends React.Component {
  columns = [
    {
      title: "Name",
      dataIndex: "profileData.name",
      width: "50%"
    },
    {
      title: "Active",
      dataIndex: "active",
      width: "30%",
      render: (active, sponsor) => {
        let color = "red";
        let text = "No";
        if (active === true) {
          color = "green";
          text = "Yes";
        }
        return (
          <span>
            <Tag color={color} key={sponsor.sid}>
              {text}
            </Tag>
          </span>
        );
      }
    },
    {
      title: "Actions",
      dataIndex: "sid",
      width: "20%",
      render: sid => (
        <div key={sid}>
          <Link to={`update/${sid}`}>
            <Button type="primary" size="small">
              Edit
            </Button>
          </Link>
          &nbsp;
          <Popconfirm
            placement="left"
            title="Are you sure you want to delete this sponsor profile?"
            onConfirm={() => this.confirm(sid)}
            onCancel={() => this.cancel(sid)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" size="small">
              Delete
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      sponsors_list: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        lastKey: "",
        firstKey: "",
        lastPage: 0
      },
      loading: false
    };
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.onCollectionUpdate({
      pagination,
      filters,
      sorter
    });
  };

  onCollectionUpdate(params = {}) {
    this.setState({ loading: true });
    const pagination = { ...this.state.pagination };
    if (params.pagination) {
      pagination.current = params.pagination.current;
    }

    this.props.sponsorsList(pagination);
  }

  componentDidMount() {
    this.onCollectionUpdate();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.sponsors &&
      nextProps.sponsors.sponsors_list &&
      nextProps.sponsors.pagination
    ) {
      const pagination = nextProps.sponsors.pagination;
      const sponsors_list = nextProps.sponsors.sponsors_list;

      this.setState({
        loading: false,
        sponsors_list,
        pagination
      });
    }
  }

  confirm = async sid => {
    this.setState({ loading: true });
    let result = await this.props.deleteSponsor(sid);
    if (result === true) {
      this.onCollectionUpdate();
    } else {
      this.setState({ loading: false });
      alert(result);
    }
  };

  cancel = sid => {
    // delete canceled
  };

  render() {
    return (
      <Card
        className="gx-card"
        title="Sponsors List"
        extra={
          <Link to={`/sponsor/create`}>
            <Button
              className="gx-btn-block ant-btn"
              type="primary"
              aria-label="add"
            >
              <i className="icon icon-add gx-mr-2" />
              <span>Add</span>
            </Button>
          </Link>
        }
      >
        <Table
          columns={this.columns}
          size={"small"}
          dataSource={this.state.sponsors_list}
          rowKey={record => record.sid}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          bordered
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, sponsor }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { sponsors } = sponsor;
  return { loader, alertMessage, showMessage, authUser, sponsors };
};

export default connect(
  mapStateToProps,
  { sponsorsList, deleteSponsor }
)(ManageSponsor);
