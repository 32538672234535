import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Switch,
  Upload,
  message,
  Icon,
  Modal
} from "antd";
import _ from "lodash";
import LocationPicker from "./LocationPicker";

const confirm = Modal.confirm;
const FormItem = Form.Item;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 }
  },
  wrapperCol: {
    xs: { span: 24 }
  },
  labelAlign: "left"
};
const socialProfileLabels = {
  facebook: "Facebook",
  instagram: "Instagram",
  linkedin: "LinkedIn",
  snapchat: "Snapchat",
  twitter: "Twitter",
  whatsapp: "WhatsApp",
  pinterest: "Pinterest",
  tiktok: "Tiktok",
  youtube: "Youtube",
  email: "Email",
  wifi: "WiFi Network",
  menu: "Menu"
};

class UserUpdateForm extends Component {
  state = {
    imageUrl: "",
    location: { lat: 25.0391667, lng: 121.525 }
  };

  componentDidMount() {
    let { imageUrl, location } = this.state;
    if (this.props.user && this.props.user.userData) {
      const data = this.props.user.userData;
      if (data.avatar) {
        imageUrl = data.avatar;
      }
      if (this.props.userLocation) {
        location = this.props.userLocation;
      }
      this.setState({ imageUrl, location });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && this.props.uid != null) {
        confirm({
          title: "Are you sure you want to update?",
          content:
            "Please confirm if you really want to save the updated values.",
          okText: "Confirm",
          onOk: () => {
            var user = _.merge(this.props.user, values.user);
            this.props.updateUser(
              this.props.uid,
              user,
              values.profile_photo,
              this.state.location
            );
          },
          onCancel() {
            console.log("Canceled update.");
          }
        });
      }
    });
  };

  beforeUpload = file => {
    const isJPG = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJPG) {
      message.error("You can only upload JPG or PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJPG && isLt2M;
  };

  onFileSelected = params => {
    this.setState({
      imageUrl: URL.createObjectURL(params.file)
    });
  };

  renderWiFiProfile = wifiProfilesKeys => {
    let socialProfile = "wifi";
    let socialProfile_1 = "wifi_ssid";
    const { getFieldDecorator } = this.props.form;
    const { user } = this.props;
    let label = socialProfileLabels[socialProfile];
    return (
      <div key={socialProfile}>

        <Row>
          <Col span={24}>
            <h4>{label}</h4>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="ID">
              {getFieldDecorator(
                "user.userData.socialProfiles." +
                  socialProfile +
                  ".userId",
                {
                  initialValue:
                    user.userData.socialProfiles &&
                    user.userData.socialProfiles[socialProfile] &&
                    user.userData.socialProfiles[socialProfile].userId
                      ? user.userData.socialProfiles[socialProfile].userId
                      : ""
                }
              )(<Input />)}
            </FormItem>
          </Col>

          <Col lg={6} md={6} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="Password">
              {getFieldDecorator(
                "user.userData.socialProfiles." +
                  socialProfile_1 +
                  ".userId",
                {
                  initialValue:
                    user.userData.socialProfiles &&
                    user.userData.socialProfiles[socialProfile_1] &&
                    user.userData.socialProfiles[socialProfile_1].userId
                      ? user.userData.socialProfiles[socialProfile_1].userId
                      : ""
                }
              )(<Input />)}
            </FormItem>
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator(
                "sponsor.userData.socialProfiles." +
                  socialProfile +
                  ".active",
                {
                  initialValue:
                    user.userData.socialProfiles &&
                    user.userData.socialProfiles[socialProfile] &&
                    user.userData.socialProfiles[socialProfile].active
                      ? user.userData.socialProfiles[socialProfile].active
                      : false,
                  valuePropName: "checked"
                }
              )(<Switch />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  };

  renderSocialProfile = socialProfile => {
    const { getFieldDecorator } = this.props.form;
    const { user } = this.props;
    const label = socialProfileLabels[socialProfile];
    return (
      <div key={socialProfile}>
        <Row>
          <Col span={24}>
            <h4>{label}</h4>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={6} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="ID">
              {getFieldDecorator(
                "user.userData.socialProfiles." +
                  socialProfile +
                  ".userId",
                {
                  initialValue:
                    user.userData.socialProfiles &&
                    user.userData.socialProfiles[socialProfile] &&
                    user.userData.socialProfiles[socialProfile].userId
                      ? user.userData.socialProfiles[socialProfile].userId
                      : ""
                }
              )(<Input />)}
            </FormItem>
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator(
                "user.userData.socialProfiles." +
                  socialProfile +
                  ".active",
                {
                  initialValue:
                    user.userData.socialProfiles &&
                    user.userData.socialProfiles[socialProfile] &&
                    user.userData.socialProfiles[socialProfile].active
                      ? user.userData.socialProfiles[socialProfile].active
                      : false,
                  valuePropName: "checked"
                }
              )(<Switch />)}
            </FormItem>
          </Col>

          <Col lg={4} md={4} sm={6} xs={12}>
            <FormItem {...formItemLayout} label="Verified">
              {getFieldDecorator(
                "user.userData.socialProfiles." +
                  socialProfile +
                  ".verified",
                {
                  initialValue:
                    user.userData.socialProfiles &&
                    user.userData.socialProfiles[socialProfile] &&
                    user.userData.socialProfiles[socialProfile].verified
                      ? user.userData.socialProfiles[socialProfile]
                          .verified
                      : false,
                  valuePropName: "checked"
                }
              )(<Switch />)}
            </FormItem>
          </Col>
        </Row>
      </div>
    );
  };

  onLocationChange = location => {
    this.setState({ location });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { user } = this.props;
    let { imageUrl, location } = this.state;

    const uploadButton = (
      <div>
        <Icon type={"plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    let socialProfiles = null;
    let wifiProfiles = null;

    if (user.userData && user.userData.socialProfiles) {
      let wifiProfilesKeys = [];
      socialProfiles = Object.keys(user.userData.socialProfiles).map(
        key => {
          if(key !== "wifi" && key !=="wifi_ssid") {
            return this.renderSocialProfile(key);
          } else {
            wifiProfilesKeys.push(key);
            return null;
          }
        }
      );

      if (wifiProfilesKeys.length === 2) {
        wifiProfiles = this.renderWiFiProfile(wifiProfilesKeys);
      }
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          <Col lg={6} md={6} sm={12} xs={18}>
            <FormItem {...formItemLayout} label="Name">
              {getFieldDecorator("user.userData.name", {
                initialValue:
                  user.userData && user.userData.name
                    ? user.userData.name
                    : "",
                rules: [
                  {
                    required: true,
                    message: "Please input user's name!",
                    whitespace: true
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>

          <Col lg={4} md={4} sm={12} xs={6}>
            <FormItem {...formItemLayout} label="Active">
              {getFieldDecorator("user.active", {
                initialValue: user.active ? user.active : false,
                valuePropName: "checked"
              })(<Switch />)}
            </FormItem>
          </Col>

          <Col lg={8} md={8} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="Status">
              {getFieldDecorator("user.userData.status", {
                initialValue:
                  user.userData && user.userData.status
                    ? user.userData.status
                    : ""
              })(<TextArea autosize={{ minRows: 5, maxRows: 5 }} />)}
            </FormItem>
          </Col>

          <Col lg={6} md={6} sm={12} xs={24}>
            <FormItem {...formItemLayout} label="Profile Photo">
              {getFieldDecorator("profile_photo", {
                valuePropName: "file"
              })(
                <Upload
                  name="profile_photo"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={this.beforeUpload}
                  customRequest={this.onFileSelected}
                >
                  {imageUrl ? <img src={imageUrl} alt="" /> : uploadButton}
                </Upload>
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <LocationPicker
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `300px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              onLocationChange={this.onLocationChange}
              location={location}
            />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <h3 style={{ marginTop: "20px" }}>Social Profiles</h3>
          </Col>
        </Row>

        {socialProfiles}
        {wifiProfiles}

        <Row>
          <Col span={24}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default Form.create()(UserUpdateForm);
