import fb from "../../firebase/firebase";
import { GOT_SPONSORS } from "../../constants/ActionTypes";

const processSponsorsList = (pagination, list) => {
  const sponsors_list = [];
  if (list) {
    const keysList = Object.keys(list);
    keysList.forEach(key => {
      const user = list[key];
      sponsors_list.push({ sid: key, ...user });
    });

    if (pagination.lastPage < pagination.current) {
      pagination.total = pagination.total + sponsors_list.length;
    }
    pagination.firstKey = sponsors_list[0].sid;
    pagination.lastKey = sponsors_list[sponsors_list.length - 1].sid;
    if (sponsors_list.length > pagination.pageSize) {
      sponsors_list.pop();
    }

    if (pagination.current !== undefined) {
      pagination.lastPage = pagination.current;
    }
  }
  return { pagination, sponsors_list };
};

export const sponsorsList = pagination => async dispatch => {
  let { current, pageSize, lastKey, firstKey, lastPage } = pagination;

  if (current === 1) {
    lastKey = " ";
  }

  if (lastPage < current || current === 1) {
    return fb
      .sponsors()
      .orderByKey()
      .limitToFirst(pageSize + 1)
      .startAt(lastKey)
      .once("value")
      .then(function(snapshot) {
        dispatch(
          loadSponsorsListSuccess(
            processSponsorsList(pagination, snapshot.val())
          )
        );
      });
  } else {
    return fb
      .sponsors()
      .orderByKey()
      .limitToLast(pageSize + 1)
      .endAt(firstKey)
      .once("value")
      .then(function(snapshot) {
        dispatch(
          loadSponsorsListSuccess(
            processSponsorsList(pagination, snapshot.val())
          )
        );
      });
  }
};

export const deleteSponsor = sid => async dispatch => {
  return fb
    .sponsor(sid)
    .remove()
    .then(function() {
      return true;
    })
    .catch(function(error) {
      return "Deletion failed: " + error.message;
    });
};

export const sponsorData = sid => async dispatch => {
  return fb
    .sponsor(sid)
    .once("value")
    .then(function(snapshot) {
      return snapshot.val();
    })
    .catch(err => {
      return null;
    });
};

export const createSponsor = sponsor => async dispatch => {
  return fb
    .createSponsor(sponsor)
    .once("value")
    .then(function(snapshot) {
      var val = snapshot.val();
      return { sid: snapshot.key, sponsor: val };
    });
};

const uploadSponsorAvatar = (sid, profile_photo) => {
  try {
    const image = fb.sponsorStorage(sid).child("avatar");
    return image.put(profile_photo.file.originFileObj).then(snapshot => {
      return image.getDownloadURL().then(url => {
        return url;
      });
    });
  } catch (err) {
    return null;
  }
};

export const updateSponsor = (
  sid,
  sponsor,
  profile_photo
) => async dispatch => {
  var updatedSponsor = sponsor;
  var avatar_url = await uploadSponsorAvatar(sid, profile_photo);
  if (avatar_url != null) {
    updatedSponsor.profileData.avatar = avatar_url;
  }
  return fb
    .sponsor(sid)
    .set(updatedSponsor)
    .then(function() {
      return { sid, sponsor: updatedSponsor };
    })
    .catch(function(error) {
      return { sid, sponsor };
    });
};

const loadSponsorsListSuccess = sponsors => ({
  type: GOT_SPONSORS,
  payload: sponsors
});
