import React from "react";
import { Avatar } from "antd";

const ProfileHeader = ({ user }) => {
  return (
    <div className="gx-profile-banner">
      <div className="gx-profile-container">
        <div className="gx-profile-banner-top" style={{ marginBottom: "0px" }}>
          <div className="gx-profile-banner-top-left">
            <div className="gx-profile-banner-avatar">
              <Avatar
                className="gx-size-90"
                alt="..."
                src={
                  user.userData && user.userData.avatar
                    ? user.userData.avatar
                    : require("assets/images/user.svg")
                }
              />
            </div>
            <div className="gx-profile-banner-avatar-info">
              <h2 className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light">
                {user.userData && user.userData.name}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
