import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Card, message } from "antd";
import { Spin } from "utils";

import SponsorUpdateForm from "./SponsorUpdateForm";
import { sponsorData, updateSponsor } from "redux/actions/Sponsor";

class UpdateSponsor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sid: null,
      sponsor: {},
      notFound: false,
      loading: true
    };
  }

  async componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    let sponsor = await this.props.sponsorData(this.props.match.params.id);
    if (sponsor) {
      this.setState({
        sid: this.props.match.params.id,
        sponsor,
        loading: false
      });
    } else {
      message.error("Sponsor not found.");
      this.setState({ notFound: true });
    }
  }

  updateSponsor = async (sid, sponsor, profile_photo) => {
    this.setState({ loading: true });
    let response = await this.props.updateSponsor(sid, sponsor, profile_photo);
    this.setState({
      sid: response.sid,
      sponsor: response.sponsor,
      loading: false
    });
  };

  render() {
    if (!this.state.notFound) {
      if (this.state.loading) {
        return (
          <Spin tip="Please wait ..." spinning={this.state.loading}></Spin>
        );
      } else {
        return (
          <Card className="gx-card" title="Update Sponsor Profile">
            <SponsorUpdateForm
              updateSponsor={this.updateSponsor}
              sid={this.state.sid}
              sponsor={this.state.sponsor}
            />
          </Card>
        );
      }
    } else {
      return <Redirect to={"/sponsor/manage"} />;
    }
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(
  mapStateToProps,
  { sponsorData, updateSponsor }
)(UpdateSponsor);
