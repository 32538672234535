import React from "react";
import { connect } from "react-redux";
import { Card, Table, Tag } from "antd";

import { reportedUsersList } from "redux/actions/User";

class ReportedUsers extends React.Component {

  columns = [
      {
        title: "ID",
        dataIndex: "reportedUid",
        width: "80%"
      },
      {
          title: "Report Counts",
          dataIndex: "reportingUids",
          width: "20%",
          render: (reportingUids, user) => {
              let color = "red";
              let text = reportingUids.length;
              return (
                <span>
                  <Tag color={color} key={user.uid}>
                    {text}
                  </Tag>
                </span>
              );
            }
        }
    ];
    
  constructor(props) {
    super(props);
    this.state = {
      users_list: [],
      loading: true
    };
  }

  getReportingUsersArray = data => {
    return data
      ? Object.keys(data).map(key => ({
          uid: key,
          reports: data[key]
        }))
      : [];
  };

  getReportedUsersArray = data => {
    return data
      ? Object.keys(data).map(key => ({
          reportedUid: key,
          reportingUids: this.getReportingUsersArray(data[key])
        }))
      : [];
  };

  componentDidMount() {
    this.setState({loading: true});
    this.props.reportedUsersList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.reported_users) {
      const users_list = this.getReportedUsersArray(nextProps.reported_users);
      this.setState({
        loading: false,
        users_list
      });
    }
  }

  render() {
    return (
      <Card className="gx-card" title={"Reported Users"}>
        <Table
          columns={this.columns}
          size={"small"}
          dataSource={this.state.users_list}
          rowKey={record => record.reportedUid}
          loading={this.state.loading}
          bordered
        />
      </Card>
    );
  }
}

const mapStateToProps = ({ auth, user }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { reported_users } = user;
  return { loader, alertMessage, showMessage, authUser, reported_users };
};

export default connect(
  mapStateToProps,
  { reportedUsersList }
)(ReportedUsers);
