import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNIN_USER_ERROR
} from "../../constants/ActionTypes";

import fb from "../../firebase/firebase";

export const userSignIn = formObj => async dispatch => {
  fb.doSignInWithEmailAndPassword(formObj.email, formObj.password)
    .then(function(user) {
      // if the login was succesful, then
      // we dispatch to our reducers the fact that
      // login was succesful by sending true
      if (user.message) {
      } else {
        localStorage.setItem("user_id", user.user.uid);
        dispatch({ type: SIGNIN_USER_SUCCESS, payload: user });
      }
    })
    .catch(function(error) {
      localStorage.removeItem("user_id");
      dispatch({ type: SIGNIN_USER_ERROR, payload: null });
    });
};

export const userSignOut = () => async dispatch => {
  fb.doSignOut()
    .then(function(authUser) {
      if (authUser === undefined) {
        localStorage.removeItem("user_id");
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      } else {
        showAuthMessage(authUser.message);
      }
    })
    .catch(error => showAuthMessage(error));
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};
